// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-attractions-js": () => import("./../../../src/pages/attractions.js" /* webpackChunkName: "component---src-pages-attractions-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dining-js": () => import("./../../../src/pages/dining.js" /* webpackChunkName: "component---src-pages-dining-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-offers-js": () => import("./../../../src/pages/get-offers.js" /* webpackChunkName: "component---src-pages-get-offers-js" */),
  "component---src-pages-guest-reviews-js": () => import("./../../../src/pages/guest-reviews.js" /* webpackChunkName: "component---src-pages-guest-reviews-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-sports-page-js": () => import("./../../../src/templates/sports-page.js" /* webpackChunkName: "component---src-templates-sports-page-js" */)
}

