import React from "react"

const BREAK_POINTS = {
  xs: { min: 0, max: 575 },
  sm: { min: 576, max: 767 },
  md: { min: 768, max: 991 },
  lg: { min: 992, max: 1199 },
  xl: { min: 1200, max: 1599 },
  xxl: { min: 1600, max: 99999 },
}

const DEVICE_TYPES = {
  phones: /Android|iPhone|iPod|Mini|Mobile/,
  tablets: /iPad|Note|Tablet/,
  desktop: /Chromebook|Mac|OS X|Windows/,
}

const getWindowProp = (prop, ssrValue) => {
  if (typeof window !== `undefined`) {
    return window[prop]
  }
  return ssrValue
}

const MediaContext = React.createContext(null)

class MediaProvider extends React.Component {
  state = {
    agent: `ssr`,
    breakpoint: `xs`,
    browser: `ssr`,
    cores: 1,
    device: `ssr`,
    height: getWindowProp(`innerHeight`, 1),
    orientation: `ssr`,
    pixelRatio: 1,
    isTouch: false,
    width: getWindowProp(`innerWidth`, 1),
    XS: BREAK_POINTS.xs.max,
    SM: BREAK_POINTS.sm.max,
    MD: BREAK_POINTS.md.max,
    LG: BREAK_POINTS.lg.max,
    XL: BREAK_POINTS.xl.max,
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.describeMedia()
      window.addEventListener(`resize`, this.handleResize)
    }
  }

  describeMedia = () => {
    const agent = window.navigator.userAgent
    const height = window.innerHeight
    const width = window.innerWidth

    this.setState({
      agent: agent,
      breakpoint: this.getBreakpoint(width),
      browser: window.navigator.appCodeName,
      cores: window.navigator.hardwareConcurrency,
      device: this.guessDevice(agent),
      height: height,
      orientation: this.getOrientation(width, height),
      pixelRatio: window.devicePixelRatio,
      isTouch: this.isTouchDevice(),
      width: width,
    })
  }

  getBreakpoint = width => {
    for (let point in BREAK_POINTS) {
      const { min, max } = BREAK_POINTS[point]
      if (width >= min && width <= max) {
        return point
      }
    }
  }

  getOrientation = (width, height) => {
    return width >= height ? `landscape` : `portrait`
  }

  guessDevice = agent => {
    for (let device in DEVICE_TYPES)
      if (DEVICE_TYPES[device].test(agent)) return device
  }

  handleResize = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    this.setState({
      breakpoint: this.getBreakpoint(width),
      height: height,
      orientation: this.getOrientation(width, height),
      width: width,
    })
  }

  isTouchDevice = () => {
    if (`ontouchstart` in window || window.DocumentTouch) return true
    else return false
  }

  render() {
    const { children } = this.props

    return (
      <MediaContext.Provider value={this.state}>
        {children}
      </MediaContext.Provider>
    )
  }
}

export default MediaContext

export { MediaProvider }
