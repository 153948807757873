const React = require(`react`)
const { MediaProvider } = require(`./src/utils/mediaContext`)

exports.onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      window.dataLayer.push({ event: `gatsby-route-change` })
    }, 50)
  }
}

exports.wrapPageElement = ({ element }) => (
  <MediaProvider>{element}</MediaProvider>
)
